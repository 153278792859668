import React from 'react';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';

import './index.scss';

const About = () => {
    useAnimateOnScroll();
    
    return (
        <section id="about" className="about section-padding bg-white">
            <div className="grid-small">
                <h2 className="about__title">
                    <span data-aos="slice-up" data-aos-duration="400" data-aos-delay="100">About me</span>
                </h2>
                <div className="about__content" data-aos="fade-in" data-aos-duration="400" data-aos-delay="100">
                    <p>Hi, I'm Zaid (pronounced /zɛd/, rhymes with Ted). I am a Software Engineer based in Houston, TX. 
                    I enjoy being given challenging, visually-pleasing designs and making them a reality. The process 
                    of tearing an idea down into its smallest components, learning, and figuring out how to put those pieces together, 
                    all while making it performant, excites me.</p>
                    <p>Right now, I'm working as a software engineer at Nebo, a software company based in Atlanta, where I contribute to building accessible, 
                    human-centered projects.</p>
                    <p>Beyond my work, you'll find me creating short cinematic videos on YouTube, hanging out with family, 
                    friends, and indulging in a love for stand-up comedy shows.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default About