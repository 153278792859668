import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TransitionContext from '../../templates/Context';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';

import './index.scss';

function Project(props) {
    useAnimateOnScroll();
  
    const { link, transitionElement } = useContext(TransitionContext);
    const TransitionLink = link;
  
    const media = props.video ? (
      <div className="project__vid">
        <div className="project__scale" data-aos="scale-down">
          <video autoPlay loop muted poster={props.image}>
            <source type="video/mp4" src={props.video} />
          </video>
        </div>
      </div>
    ) : (
      <figure className="project__img">
        <div className="project__scale" data-aos="scale-down">
          <img src={props.image} alt={props.title} />
        </div>
      </figure>
    );
  
    return (
      <article className="project" data-aos="slide-up">
        <TransitionLink
          className="project__link"
          to={props.link}
          transitionElement={transitionElement}
        >
          {media}
          <header className="project__header">
            <h3 className="project__title">
              <span data-aos="slice-up" data-aos-duration="400">{props.title}</span>
            </h3>
            <p className="project__text" data-aos="fade-in" data-aos-duration="400" data-aos-delay="100">{props.description}</p>
            <div className="project__tech">
              <p className="project__tech__skills">{props.skills}</p>
            </div>
          </header>
        </TransitionLink>
      </article>
    );
  }
  
  Project.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    video: PropTypes.string,
  };
  
  Project.defaultProps = {
    video: '',
  };
  
  export default Project;
