import React from 'react';
import useAnimateOnScroll from '../../utils/useAnimateOnScroll';
import IconHtml from './icons/icon_html.svg'
import IconCSS from './icons/icon_css.svg'
import IconSASS from './icons/icon_sass.svg'
import IconJs from './icons/icon_javascript.svg'
import IconReact from './icons/icon_react.svg'
import IconRedux from './icons/icon_redux.svg'
import IconSC from './icons/icon_styledComponents.svg'
import Iconjq from './icons/icon_jquery.svg'
import IconNodeJS from './icons/icon_nodejs.svg'
import IconExpress from './icons/icon_express.svg'
import IconMongoDB from './icons/icon_mongodb.svg'
import IconGit from './icons/icon_git.svg'
import IconGithub from './icons/icon_github.svg'
import IconWebpack from './icons/icon_webpack.svg'
import IconJest from './icons/icon_jest.svg'
import IconBalsamiq from './icons/balsamiq.png'

import './index.scss';

const Skills = () => {

    useAnimateOnScroll();

    return (
        <section id="skills" className="skills section-padding bg-lightgrey">
            <div className="grid">
                <h2 className="skills__title">
                    <span data-aos="slice-up" data-aos-duration="400" data-aos-delay="100">Skills</span>
                </h2>
                <div className="skills__cards">
                    <div className="skills__cardWrapper">
                        <h1>Front End</h1>
                        <div className="skills__card">
                            <div className="skills__cardRow" style={{ justifyContent: 'space-between', padding: '0 20px' }}>
                                <div className="skills__cardItem">
                                    <IconHtml />
                                    <h2>HTML</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <IconCSS />
                                    <h2>CSS</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <IconSASS />
                                    <h2>SASS</h2>
                                </div>
                            </div>

                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconJs />
                                    <h2>JavaScript</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <IconReact />
                                    <h2>React</h2>
                                </div>

                            </div>

                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconRedux />
                                    <h2>Redux</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <Iconjq />
                                    <h2>jQuery</h2>
                                </div>
                            </div>

                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconSC />
                                    <h2>styled-components</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skills__cardWrapper">
                        <h1>Back End</h1>
                        <div className="skills__card backend">
                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconNodeJS />
                                    <h2>Node.js</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <IconExpress />
                                    <h2>Express.js</h2>
                                </div>
                            </div>
                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <h2>RESTful API</h2>
                                </div>
                            </div>
                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconMongoDB />
                                    <h2>MongoDB</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skills__cardWrapper">
                        <h1>Other</h1>
                        <div className="skills__card backend">
                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconGit />
                                    <h2>Git</h2>
                                </div>

                                <div className="skills__cardItem">
                                    <IconGithub />
                                    <h2>GitHub</h2>
                                </div>
                            </div>
                            <div className="skills__cardRow">
                                <div className="skills__cardItem balsamiq">
                                    <img src={IconBalsamiq} />
                                    <h2>Balsamiq</h2>
                                </div>
                                <div className="skills__cardItem">
                                    <IconWebpack />
                                    <h2>webpack</h2>
                                </div>
                            </div>
                            <div className="skills__cardRow">
                                <div className="skills__cardItem">
                                    <IconJest />
                                    <h2>Jest</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ofcourse">
                    <h4>*Very open to work with stuff not listed above, of course.</h4>
                </div>
            </div>
            
        </section>
    )
}

export default Skills