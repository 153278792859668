import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Intro from '../components/Intro'
import CTA from '../components/CTA'
import Projects from '../components/Projects'
import About from '../components/About'
import Skills from '../components/Skills'


function IndexPage() {

    const { site, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          projects {
            description
            image
            title
            video
            skills
          }
        }
      } 
    }
  `);

    const projects = site.siteMetadata.projects;
    

    return (
        <Fragment>
            <Intro />
            <Projects projects={projects} />
            <About /> 
            <Skills />
            <CTA />
        </Fragment>
    )
}

export default IndexPage
